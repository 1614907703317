import { IUrlItem } from './../interfaces/IUrlItem';

const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';

export const navigationMenu: IUrlItem[] = [
  {
    id: 'companies',
    title: 'For Companies',
    url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/hire-wise/`,
    subitems: [
      {
        id: 'hire-wise',
        title: 'HireWise',
        url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/hire-wise/`,
      },
    ],
  },
  {
    id: 'recruiters',
    title: 'For Recruiters',
    url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/hire-club/`,
    subitems: [
      {
        id: 'hire-club',
        title: 'HireClub',
        url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/hire-club/`,
      },
    ],
  },
  {
    id: 'candidates',
    title: 'For Candidates',
    url: `/jobs/`,
    subitems: [
      {
        id: 'jobs',
        title: 'Jobs',
        url: '/jobs/',
      },
    ],
  },
  {
    id: 'about',
    title: 'About Us',
    url: '/about-us/',
    subitems: [
      {
        id: 'success',
        title: 'Success Stories',
        url: '/success-stories/',
      },
      {
        id: 'blog',
        title: 'Blog',
        url: '/blogs/',
      },
    ],
  },
];
